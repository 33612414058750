.shepherd-container {
  background-color: white;
  border-radius: 0%;
  border: 3px solid black;
  margin: 20px;
  padding-top: 10px;
  padding-bottom: 5px;
  padding-left: 43px;
  padding-right: 17px;
}

.shepherd-text {
  padding: 0;
  font-size: 25px;
  line-height: normal;
}

@media only screen and (max-width: 1367px) {
  .shepherd-text {
    font-size: 18px;
  }

  .btn {
    font-size: 15px;
  }

  .shepherd-container[data-shepherd-step-id='lateral-integrated-1'] {
    top: 300px !important;
    left: 300px !important;
  }

  .shepherd-container[data-shepherd-step-id='lateral-3'] {
    top: 20% !important;
    left: 50% !important;
  }

  .shepherd-container[data-shepherd-step-id='lateral-on-line-1'] {
    top: 30% !important;
    left: 20% !important;
  }

  .shepherd-container[data-shepherd-step-id='lateral-on-line-2'] {
    top: 20% !important;
    left: 20% !important;
  }

  .shepherd-container[data-shepherd-step-id='lateral-on-line-3'] {
    top: 30% !important;
    left: 20% !important;
  }
  .shepherd-container[data-shepherd-step-id='lateral-integrated-2'] {
    top: 150px !important;
    left: 10% !important;
  }
  .shepherd-container[data-shepherd-step-id='lateral-max-1'] {
    top: 20% !important;
    left: 300px !important;
  }

  .shepherd-container[data-shepherd-step-id='lateral-define-2'] {
    top: 50% !important;
    left: 300px !important;
  }

  .shepherd-container[data-shepherd-step-id='lateral-define-1'] {
    top: 20% !important;
    left: 20% !important;
  }

  .shepherd-container[data-shepherd-step-id='lateral-5'] {
    top: 500px !important;
    left: 200px !important;
  }

  .shepherd-container[data-shepherd-step-id='sidebar-1'] {
    top: 500px !important;
    left: 200px !important;
  }

  .shepherd-container[data-shepherd-step-id='submain-1'] {
    top: 150px !important;
    left: 200px !important;
  }

  .shepherd-container[data-shepherd-step-id='submain-2'] {
    top: 40% !important;
    left: 200px !important;
  }

  .shepherd-container[data-shepherd-step-id='submain-3'] {
    top: 150px !important;
    left: 50% !important;
  }

  .shepherd-container[data-shepherd-step-id='submain-calc'] {
    top: 40% !important;
    left: 200px !important;
  }

  .shepherd-container[data-shepherd-step-id='submain-6'] {
    top: 450px !important;
    left: 200px !important;
  }

  .shepherd-container[data-shepherd-step-id='submain-5'] {
    top: 40% !important;
    left: 700px !important;
  }

  .shepherd-container[data-shepherd-step-id='submain-7'] {
    top: 70% !important;
    left: 650px !important;
  }

  .shepherd-container[data-shepherd-step-id='submain-8'] {
    top: 500px !important;
    left: 200px !important;
  }

  .shepherd-container[data-shepherd-step-id='mainline-1'] {
    top: 250px !important;
    left: 200px !important;
  }

  .shepherd-container[data-shepherd-step-id='mainline-2'] {
    top: 150px !important;
    left: 40% !important;
  }

  .shepherd-container[data-shepherd-step-id='mainline-3'] {
    top: 280px !important;
    left: 10% !important;
  }

  .shepherd-container[data-shepherd-step-id='sys-sum-1'] {
    left: 200px !important;
    top: 500px !important;
  }
  .shepherd-container[data-shepherd-step-id='sys-sum-3'] {
    top: 100px !important;
    left: 800px !important;
  }

  .shepherd-container[data-shepherd-step-id='sys-sum-4'] {
    top: 50% !important;
    left: 50% !important;
  }

  .shepherd-container[data-shepherd-step-id='mainline-3'] {
    top: 500px !important;
    left: 200px !important;
  }

  .shepherd-container[data-shepherd-step-id='edc-1'] {
    top: 350px !important;
    left: 200px !important;
  }

  .shepherd-container[data-shepherd-step-id='edc-2'] {
    top: 150px !important;
    left: 200px !important;
  }

  .shepherd-container[data-shepherd-step-id='edc-3'] {
    top: 550px !important;
    left: 200px !important;
  }

  .shepherd-container[data-shepherd-step-id='edc-5'] {
    height: 250px !important;
  }

  .shepherd-container[data-shepherd-step-id='edc-6'] {
    left: 350px !important;
    height: 220px !important;
    width: 360px !important;
  }

  .shepherd-container[data-shepherd-step-id='edc-17'] {
    top: 150px !important;
  }

  .shepherd-container[data-shepherd-step-id='edc-7'] {
    left: 100px !important;
    height: 100px !important;
  }

  .shepherd-container[data-shepherd-step-id='edc-8'] {
    top: 300px !important;
    left: 200px !important;
    height: 100px !important;
  }

  .shepherd-container[data-shepherd-step-id='edc-9'] {
    height: 100px !important;
  }

  .shepherd-container[data-shepherd-step-id='edc-10'] {
    height: 100px !important;
  }

  .shepherd-container[data-shepherd-step-id='edc-11'] {
    height: 100px !important;
  }

  .shepherd-container[data-shepherd-step-id='edc-12'] {
    height: 100px !important;
  }
  .shepherd-container[data-shepherd-step-id='edc-13'] {
    height: 100px !important;
  }
  .shepherd-container[data-shepherd-step-id='edc-14'] {
    height: 100px !important;
  }
  .shepherd-container[data-shepherd-step-id='edc-15'] {
    height: 100px !important;
  }
}

@media only screen and (max-width: 1919px) and (min-width: 1600px) {
  .shepherd-text {
    font-size: 18px;
  }

  .btn {
    font-size: 15px;
  }

  .shepherd-container[data-shepherd-step-id='lateral-integrated-1'] {
    top: 300px !important;
    left: 300px !important;
  }

  .shepherd-container[data-shepherd-step-id='lateral-3'] {
    top: 50% !important;
    left: 50% !important;
  }
  .shepherd-container[data-shepherd-step-id='lateral-integrated-2'] {
    top: 150px !important;
    left: 800px !important;
  }
  .shepherd-container[data-shepherd-step-id='lateral-max-1'] {
    top: 20% !important;
    left: 300px !important;
  }

  .shepherd-container[data-shepherd-step-id='lateral-define-2'] {
    top: 50% !important;
    left: 300px !important;
  }

  .shepherd-container[data-shepherd-step-id='lateral-5'] {
    top: 500px !important;
    left: 200px !important;
  }

  .shepherd-container[data-shepherd-step-id='sidebar-1'] {
    top: 600px !important;
    left: 200px !important;
  }

  .shepherd-container[data-shepherd-step-id='submain-1'] {
    top: 150px !important;
    left: 200px !important;
  }

  .shepherd-container[data-shepherd-step-id='submain-2'] {
    top: 40% !important;
    left: 200px !important;
  }

  .shepherd-container[data-shepherd-step-id='submain-3'] {
    top: 150px !important;
    left: 50% !important;
  }

  .shepherd-container[data-shepherd-step-id='submain-calc'] {
    top: 20% !important;
    left: 200px !important;
  }

  .shepherd-container[data-shepherd-step-id='submain-6'] {
    top: 450px !important;
    left: 200px !important;
  }

  .shepherd-container[data-shepherd-step-id='submain-5'] {
    top: 60% !important;
    left: 700px !important;
  }

  .shepherd-container[data-shepherd-step-id='submain-7'] {
    top: 70% !important;
    left: 650px !important;
  }

  .shepherd-container[data-shepherd-step-id='submain-8'] {
    top: 500px !important;
    left: 200px !important;
  }

  .shepherd-container[data-shepherd-step-id='mainline-1'] {
    top: 250px !important;
    left: 200px !important;
  }

  .shepherd-container[data-shepherd-step-id='mainline-2'] {
    top: 150px !important;
    left: 50% !important;
  }

  .shepherd-container[data-shepherd-step-id='mainline-3'] {
    top: 280px !important;
    left: 10% !important;
  }

  .shepherd-container[data-shepherd-step-id='sys-sum-1'] {
    left: 200px !important;
    top: 500px !important;
  }
  .shepherd-container[data-shepherd-step-id='sys-sum-3'] {
    top: 100px !important;
    left: 800px !important;
  }

  .shepherd-container[data-shepherd-step-id='sys-sum-4'] {
    top: 50% !important;
    left: 50% !important;
  }

  .shepherd-container[data-shepherd-step-id='mainline-3'] {
    top: 500px !important;
    left: 200px !important;
  }

  .shepherd-container[data-shepherd-step-id='lateral-on-line-3'] {
    left: 50% !important;
  }

  .shepherd-container[data-shepherd-step-id='edc-1'] {
    left: 52% !important;
  }

  .shepherd-container[data-shepherd-step-id='edc-3'] {
    top: 80px !important;
    left: 13% !important;
  }

  .shepherd-container[data-shepherd-step-id='edc-4'] {
    left: 50% !important;
    top: 10% !important;
  }

  .shepherd-container[data-shepherd-step-id='edc-5'] {
    left: 70% !important;
    top: 60% !important;
    height: 300px !important;
  }

  .shepherd-container[data-shepherd-step-id='edc-6'] {
    left: 55% !important;
  }

  .shepherd-container[data-shepherd-step-id='edc-6'] img {
    height: 250px !important;
    width: 380px !important;
  }

  .shepherd-container[data-shepherd-step-id='edc-7'] {
    left: 53% !important;
  }

  .shepherd-container[data-shepherd-step-id='edc-8'] {
    height: 100px !important;
  }

  .shepherd-container[data-shepherd-step-id='edc-9'] {
    left: 10% !important;
  }

  .shepherd-container[data-shepherd-step-id='edc-10'] {
    left: 10% !important;
  }

  .shepherd-container[data-shepherd-step-id='edc-11'] {
    left: 10% !important;
  }

  .shepherd-container[data-shepherd-step-id='edc-12'] {
    left: 10% !important;
  }

  .shepherd-container[data-shepherd-step-id='edc-13'] {
    left: 10% !important;
  }

  .shepherd-container[data-shepherd-step-id='edc-14'] {
    left: 10% !important;
  }

  .shepherd-container[data-shepherd-step-id='edc-15'] {
    left: 10% !important;
  }
}

@media only screen and (max-width: 1537px) and (min-width: 1400px) {
  .shepherd-text {
    font-size: 18px;
  }

  .btn {
    font-size: 15px;
  }

  .shepherd-container[data-shepherd-step-id='lateral-on-line-3'] {
    left: 50% !important;
  }

  .shepherd-container[data-shepherd-step-id='lateral-integrated-1'] {
    top: 300px !important;
    left: 300px !important;
  }
  .shepherd-container[data-shepherd-step-id='lateral-integrated-2'] {
    top: 10% !important;
    left: 300px !important;
  }
  .shepherd-container[data-shepherd-step-id='lateral-max-1'] {
    top: 30% !important;
    left: 300px !important;
  }

  .shepherd-container[data-shepherd-step-id='lateral-define-2'] {
    top: 50% !important;
    left: 300px !important;
  }

  .shepherd-container[data-shepherd-step-id='lateral-define-1'] {
    top: 10% !important;
    left: 30% !important;
  }

  .shepherd-container[data-shepherd-step-id='lateral-5'] {
    top: 500px !important;
    left: 200px !important;
  }

  .shepherd-container[data-shepherd-step-id='lateral-3'] {
    top: 70% !important;
    left: 20% !important;
  }

  .shepherd-container[data-shepherd-step-id='sidebar-1'] {
    top: 600px !important;
    left: 200px !important;
  }

  .shepherd-container[data-shepherd-step-id='submain-1'] {
    top: 150px !important;
    left: 200px !important;
  }

  .shepherd-container[data-shepherd-step-id='submain-2'] {
    top: 250px !important;
    left: 200px !important;
  }

  .shepherd-container[data-shepherd-step-id='submain-3'] {
    top: 150px !important;
    left: 200px !important;
  }

  .shepherd-container[data-shepherd-step-id='submain-calc'] {
    top: 300px !important;
    left: 200px !important;
  }

  .shepherd-container[data-shepherd-step-id='submain-6'] {
    top: 450px !important;
    left: 200px !important;
  }

  .shepherd-container[data-shepherd-step-id='submain-5'] {
    top: 300px !important;
    left: 700px !important;
  }

  .shepherd-container[data-shepherd-step-id='submain-7'] {
    top: 400px !important;
    left: 650px !important;
  }

  .shepherd-container[data-shepherd-step-id='submain-8'] {
    top: 500px !important;
    left: 200px !important;
  }

  .shepherd-container[data-shepherd-step-id='mainline-1'] {
    top: 250px !important;
    left: 200px !important;
  }

  .shepherd-container[data-shepherd-step-id='mainline-2'] {
    top: 150px !important;
    left: 200px !important;
  }

  .shepherd-container[data-shepherd-step-id='mainline-3'] {
    top: 280px !important;
    left: 200px !important;
  }

  .shepherd-container[data-shepherd-step-id='sys-sum-1'] {
    left: 200px !important;
    top: 500px !important;
  }
  .shepherd-container[data-shepherd-step-id='sys-sum-3'] {
    top: 100px !important;
    left: 800px !important;
  }

  .shepherd-container[data-shepherd-step-id='sys-sum-4'] {
    top: 50% !important;
    left: 200px !important;
  }

  .shepherd-container[data-shepherd-step-id='mainline-3'] {
    top: 500px !important;
    left: 200px !important;
  }

  .shepherd-container[data-shepherd-step-id='edc-1'] {
    top: 350px !important;
    left: 200px !important;
  }

  .shepherd-container[data-shepherd-step-id='edc-2'] {
    top: 100px !important;
    left: 200px !important;
  }

  .shepherd-container[data-shepherd-step-id='edc-3'] {
    top: 550px !important;
    left: 200px !important;
  }

  .shepherd-container[data-shepherd-step-id='edc-4'] {
    width: 460px !important;
    height: 420px !important;
  }

  .shepherd-container[data-shepherd-step-id='edc-4'] img {
    width: 400px !important;
    height: 400px !important;
  }

  .shepherd-container[data-shepherd-step-id='edc-5'] {
    height: 300px !important;
  }
  .shepherd-container[data-shepherd-step-id='edc-6'] {
    left: 170px !important;
    top: 100px !important;
    height: 400px !important;
    width: 520px !important;
  }

  .shepherd-container[data-shepherd-step-id='edc-6'] img {
    /* left: 100px !important;
    top: 200px !important; */
    height: 350px !important;
    width: 450px !important;
  }

  .shepherd-container[data-shepherd-step-id='edc-7'] {
    height: 150px !important;
    left: 100px !important;
  }
  .shepherd-container[data-shepherd-step-id='edc-8'] {
    top: 300px !important;
    left: 200px !important;
  }

  .shepherd-container[data-shepherd-step-id='edc-9'] {
    height: 90px !important;
  }

  .shepherd-container[data-shepherd-step-id='edc-10'] {
    height: 90px !important;
  }

  .shepherd-container[data-shepherd-step-id='edc-11'] {
    height: 90px !important;
  }

  .shepherd-container[data-shepherd-step-id='edc-12'] {
    height: 90px !important;
  }

  .shepherd-container[data-shepherd-step-id='edc-13'] {
    height: 90px !important;
  }

  .shepherd-container[data-shepherd-step-id='edc-14'] {
    height: 90px !important;
  }

  .shepherd-container[data-shepherd-step-id='edc-15'] {
    height: 90px !important;
  }

  .shepherd-container[data-shepherd-step-id='edc-17'] {
    top: 220px !important;
  }
}

@media only screen and (min-width: 1919px) {
  .shepherd-container[data-shepherd-step-id='sys-sum-1'] {
    left: 1150px !important;
  }

  .shepherd-container[data-shepherd-step-id='sys-sum-3'] {
    left: 1150px !important;
  }

  .shepherd-container[data-shepherd-step-id='sys-sum-4'] {
    left: 600px !important;
  }

  .shepherd-container[data-shepherd-step-id='lateral-5'] {
    top: 300px !important;
    left: 1000px !important;
  }

  .shepherd-container[data-shepherd-step-id='submain-8'] {
    top: 250px !important;
    left: 1000px !important;
  }

  .shepherd-container[data-shepherd-step-id='mainline-3'] {
    width: 600px !important;
    top: 250px !important;
    left: 1000px !important;
  }

  .shepherd-container[data-shepherd-step-id='edc-3'] {
    top: 80px !important;
    left: 250px !important;
  }

  .shepherd-container[data-shepherd-step-id='edc-4'] {
    left: 55% !important;
    width: 694px !important;
  }

  .shepherd-container[data-shepherd-step-id='edc-5'] {
    top: 70% !important;
    left: 75% !important;
  }

  .shepherd-container[data-shepherd-step-id='edc-8'] {
    top: 70% !important;
  }

  .shepherd-container[data-shepherd-step-id='edc-9'] {
    left: 300px !important;
  }
  .shepherd-container[data-shepherd-step-id='edc-10'] {
    left: 300px !important;
  }

  .shepherd-container[data-shepherd-step-id='edc-11'] {
    left: 300px !important;
  }

  .shepherd-container[data-shepherd-step-id='edc-12'] {
    left: 300px !important;
  }

  .shepherd-container[data-shepherd-step-id='edc-13'] {
    left: 300px !important;
  }

  .shepherd-container[data-shepherd-step-id='edc-14'] {
    left: 300px !important;
  }

  .shepherd-container[data-shepherd-step-id='edc-15'] {
    left: 300px !important;
  }
}

.shepherd-container[data-shepherd-step-id='sys-sum-5'] {
  left: 200px !important;
}

.shepherd-container[data-shepherd-step-id='sys-sum-6'] {
  top: 500px !important;
  width: 600px !important;
  left: 180px !important;
}

.shepherd-element {
  width: auto;
  max-width: 900px;
}

.shepherd-header {
  position: absolute;
  right: 0;
  top: -10px;
}

.shepherd-cancel-icon {
  font-size: 25px;
  color: black;
}

.shepherd-arrow {
  display: none;
}

.shepherd-button {
  position: absolute;
  width: 52%;
  height: 54px;
  background-color: #e0e0e0;
  border: 3px solid black;
  border-radius: 0%;
  color: #2d2d2d;
  font-size: 20px;
  font-weight: 600;
}

.btn-success {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 100%;
  right: -3px;
  padding-right: 0;
  column-gap: 20px;
}

.btn-success::after {
  display: inline-block;
  position: absolute;
  right: 0;
  content: '';
  background-image: url('../../public/assets/arrowright.svg');
  background-repeat: no-repeat;
  background-size: 50px 34px;
  height: 34px;
  width: 50px;
}

.btn-info {
  position: absolute;
  top: 100%;
  left: -3px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 0;
  column-gap: 20px;
}

.btn-info::before {
  display: inline-block;
  position: absolute;
  left: 0;
  content: '';
  background-image: url('../../public/assets/arrowleft.svg');
  background-repeat: no-repeat;
  background-size: 50px 34px;
  height: 34px;
  width: 50px;
}
